import { ClassNames } from "@emotion/react";

export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoSection = {
  popularGames: {
    aviatorSprb: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.gif",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    AndarBaharEzg: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/ezugi/AndarBahar.webp",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    AutoRouletteEzg: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/AutoRoulette.webp",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    SpeedRouletteEzg: {
      redirectUrl: "/casino/ezg-speed-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/SpeedRoulette.webp",
      alt: "",
      name: "Speed Roulette",
      providerName: "Ezugi",
    },
    LightningRouletteEvo: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000092",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Lightiningroulette.jpg",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    DragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/evolution/DragonTiger.webp",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
    BaccaratEzg: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/Baccarat.webp",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    Lucky7Ezg: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Lucky7.jpg",
      alt: "",
      name: "Lucky 7",
      providerName: "Ezugi",
    },
    CrazyTimeEvo: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/CrazyTime.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    OnedayteenpattiEzg: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Onedayteenpatti.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
  },

  AndarBahar: {
    AndarBaharEzg: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/ezugi/AndarBahar.webp",
      alt: "Andar Bahar",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    OTTAndarBaharEzg: {
      redirectUrl: "/casino/ezugi/OTTAndarBahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/ezugi/OTTAndarBahar.webp",
      alt: "OTT Andar Bahar",
      name: "OTT Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateAndarBaharEzg: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/ezugi/UltimateAndarBahar.webp",
      alt: "Ultimate Andar Bahar",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },

    AndarBaharAura: {
      redirectUrl: "/casino/aura-andar-bahar",
      code: "",
      casino: "aura",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/aura/AndarBahar.webp",
      alt: "Andar Bahar",
      name: "Andar Bahar",
      providerName: "Aura",
    },
    AndarBaharVirtualAura: {
      redirectUrl: "/casino/aura-andar-bahar-virtual",
      code: "",
      casino: "aura",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/aura/AndarBaharVirtual.webp",
      alt: "Andar Bahar (Virtual)",
      name: "Andar Bahar (Virtual)",
      providerName: "Aura",
    },

    AndarBaharVivo: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/vivo/AndarBahar.webp",
      alt: "andar bahar",
      name: "Andar Bahar",
      providerName: "Vivo",
    },

    AndarBaharSn: {
      redirectUrl: "/casino/sn-andar-bahar",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/supernowa/AndarBahar.webp",
      alt: "Andar Bahar",
      name: "Andar Bahar",
      providerName: "Supernowa",
    },
    ClassicAndarBaharSn: {
      redirectUrl: "/casino/sn-classic-andar-bahar",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/supernowa/ClassicAndarBahar.webp",
      alt: "Classic Andar Bahar",
      name: "Classic Andar Bahar",
      providerName: "Supernowa",
    },
    GoasAndarBaharSn: {
      redirectUrl: "/casino/sn-goas-andar-bahar",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/supernowa/Goa'sAndarBahar.webp",
      alt: "Goa's Andar Bahar",
      name: "Goa's Andar Bahar",
      providerName: "Supernowa",
    },
    // RNGAndarBahar2020Sn: {
    //   redirectUrl: "/casino/sn-rng-andar-bahar-2020",
    //   code: "",
    //   casino: "wco",
    //   provider: "SN",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/andarbahar/supernowa/RNGAndarBahar2020.webp",
    //   alt: "RNG Andar Bahar 2020",
    //   name: "RNG Andar Bahar 2020",
    //   providerName: "Supernowa",
    // },
  },

  roulettetab: {
    AutoRouletteEzg: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/AutoRoulette.webp",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    CasinoMarinaRoulette1Ezg: {
      redirectUrl: "/casino/ezg-casino-marina-roulette-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/CasinoMarinaRoulette1.webp",
      alt: "",
      name: "Casino Marina Roulette 1",
      providerName: "Ezugi",
    },
    CasinoMarinaRoulette2Ezg: {
      redirectUrl: "/casino/ezg-casino-marina-roulette-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/CasinoMarinaRoulette2.webp",
      alt: "",
      name: "Casino Marina Roulette 2",
      providerName: "Ezugi",
    },
    CricketAutoRouletteEzg: {
      redirectUrl: "/casino/ezg-cricket-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/CricketAutoRoulette.webp",
      alt: "",
      name: "Cricket Auto Roulette",
      providerName: "Ezugi",
    },
    DiamondRouletteEzg: {
      redirectUrl: "/casino/ezg-diamond-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/DiamondRoulette.webp",
      alt: "",
      name: "Diamond Roulette",
      providerName: "Ezugi",
    },
    FirstPersonAmericanRouletteEzg: {
      redirectUrl: "/casino/ezg-first-person-american-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/FirstPersonAmericanRoulette.webp",
      alt: "",
      name: "First Person American Roulette",
      providerName: "Ezugi",
    },
    FootballAutoRouletteEzg: {
      redirectUrl: "/casino/ezg-football-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/FootballAutoRoulette.webp",
      alt: "",
      name: "Football Auto Roulette",
      providerName: "Ezugi",
    },
    ItalianRouletteEzg: {
      redirectUrl: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/ItalianRoulette.webp",
      alt: "",
      name: "Italian Roulette",
      providerName: "Ezugi",
    },
    NamasteRouletteEzg: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/NamasteRoulette.webp",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    RoletaDaSorteEzg: {
      redirectUrl: "/casino/ezg-roleta-da-sorte",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/RoletaDaSorte.webp",
      alt: "",
      name: "Roleta Da Sorte",
      providerName: "Ezugi",
    },
    // RuletaAEzg: {
    //   redirectUrl: "/casino/ezg-ruleta-a",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/roulette/ezugi/RuletaA.webp",
    //   alt: "",
    //   name: "Ruleta A",
    //   providerName: "Ezugi",
    // },
    RuletaDelSolEzg: {
      redirectUrl: "/casino/ezg-ruleta-del-sol",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/RuletaDelSol.webp",
      alt: "",
      name: "Ruleta Del Sol",
      providerName: "Ezugi",
    },
    RussianRouletteEzg: {
      redirectUrl: "/casino/ezg-russian-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/RussianRoulette.webp",
      alt: "",
      name: "Russian Roulette",
      providerName: "Ezugi",
    },
    SkylineRouletteEzg: {
      redirectUrl: "/casino/ezg-skyline-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/SkylineRoulette.webp",
      alt: "",
      name: "Skyline Roulette",
      providerName: "Ezugi",
    },
    SpanishRouletteEzg: {
      redirectUrl: "/casino/ezg-spanish-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/SpanishRoulette.webp",
      alt: "",
      name: "Spanish Roulette",
      providerName: "Ezugi",
    },
    SpeedAutoRouletteEzg: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/SpeedAutoRoulette.webp",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },
    SpeedRouletteEzg: {
      redirectUrl: "/casino/ezg-speed-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/SpeedRoulette.webp",
      alt: "",
      name: "Speed Roulette",
      providerName: "Ezugi",
    },
    TurkishRouletteEzg: {
      redirectUrl: "/casino/ezg-turkish-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/TurkishRoulette.webp",
      alt: "",
      name: "Turkish Roulette",
      providerName: "Ezugi",
    },
    UltimateRouletteEzg: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/UltimateRoulette.webp",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    VIPRouletteEzg: {
      redirectUrl: "/casino/ezg-vip-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/VIPRoulette.webp",
      alt: "",
      name: "VIP Roulette",
      providerName: "Ezugi",
    },

    AmericanRouletteEvo: {
      redirectUrl: "/casino/ezgevo-american-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/AmericanRoulette.webp",
      alt: "",
      name: "American Roulette",
      providerName: "Evolution",
    },
    AutoRouletteEvo: {
      redirectUrl: "/casino/ezgevo-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/AutoRoulette.webp",
      alt: "",
      name: "Auto Roulette",
      providerName: "Evolution",
    },
    DoubleBallRouletteEvo: {
      redirectUrl: "/casino/ezgevo-double-ball-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/DoubleBallRoulette.webp",
      alt: "",
      name: "Double Ball Roulette",
      providerName: "Evolution",
    },
    DragonaraRouletteEvo: {
      redirectUrl: "/casino/ezgevo-dragonara-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/DragonaraRoulette.webp",
      alt: "",
      name: "Dragonara Roulette",
      providerName: "Evolution",
    },
    FirstPersonAmericanRouletteEvo: {
      redirectUrl: "/casino/ezgevo-first-person-american-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/FirstPersonAmericanRoulette.webp",
      alt: "",
      name: "First Person American Roulette",
      providerName: "Evolution",
    },
    FootballStudioRouletteEvo: {
      redirectUrl: "/casino/ezgevo-football-studio-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/FootballStudioRoulette.webp",
      alt: "",
      name: "Football Studio Roulette",
      providerName: "Evolution",
    },
    InstantRouletteEvo: {
      redirectUrl: "/casino/ezgevo-instant-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/InstantRoulette.webp",
      alt: "",
      name: "Instant Roulette",
      providerName: "Evolution",
    },
    LightningRouletteEvo: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/LightningRoulette.webp",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    RoulettEvo: {
      redirectUrl: "/casino/ezgevo-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/Roulett.webp",
      alt: "",
      name: "Roulett",
      providerName: "Evolution",
    },
    SpeedAutoRouletteEvo: {
      redirectUrl: "/casino/ezgevo-speed-auto-roulette",
      code: "1000103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/SpeedAutoRoulette.webp",
      alt: "",
      name: "Speed Auto Roulette-EVO",
      providerName: "Evolution",
    },
    SpeedRouletteEvo: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/SpeedRoulette.webp",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    TurkishLightningRouletteEvo: {
      redirectUrl: "/casino/ezgevo-turkish-lightning-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/TurkishLightningRoulette.webp",
      alt: "",
      name: "Turkish Lightning Roulette",
      providerName: "Evolution",
    },
    VIPRouletteEvo: {
      redirectUrl: "/casino/ezgevo-vip-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/VipRoulette.webp",
      alt: "",
      name: "VIP Roulette",
      providerName: "Evolution",
    },
    XxxtremeLightningRouletteEvo: {
      redirectUrl: "/casino/ezgevo-xxxtreme-lightning-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/XxxtremeLightningRoulette.webp",
      alt: "",
      name: "Xxxtreme Lightning Roulette",
      providerName: "Evolution",
    },

    RouletteAura: {
      redirectUrl: "/casino/aura-roulette",
      code: "",
      casino: "aura",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/aura/Roulette.webp",
      alt: "",
      name: "Roulette",
      providerName: "Aura",
    },

    EuropeanAutoRouletteVivo: {
      redirectUrl: "/casino/vivo-european-auto-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/vivo/EuropeanAutoRoulette.webp",
      alt: "",
      name: "European Auto Roulette",
      providerName: "Vivo",
    },
    FrenchRouletteVivo: {
      redirectUrl: "/casino/vivo-french-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/vivo/FrenchRoulette.webp",
      alt: "",
      name: "French Roulette",
      providerName: "Vivo",
    },
    GalaxyRouletteVivo: {
      redirectUrl: "/casino/vivo-galaxy-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/vivo/GalaxyRoulette.webp",
      alt: "",
      name: "Galaxy Roulette",
      providerName: "Vivo",
    },
    LasVegasRouletteVivo: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/vivo/LasVegasRoulette.webp",
      alt: "las vegas roulette",
      name: "Las Vegas Roulette",
      providerName: "Vivo",
    },
    Oracle360RouletteVivo: {
      redirectUrl: "/casino/vivo-oracle-360-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/vivo/Oracle360Roulette.webp",
      alt: "",
      name: "Oracle 360 Roulette",
      providerName: "Vivo",
    },
    OracleBlazeRouletteVivo: {
      redirectUrl: "/casino/vivo-oracle-blaze-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/vivo/OracleBlazeRoulette.webp",
      alt: "oracle blaze roulette",
      name: "Oracle Blaze Roulette",
      providerName: "Vivo",
    },
    PortomasoRouletteVivo: {
      redirectUrl: "/casino/vivo-portomaso-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/vivo/PortomasoRoulette.webp",
      alt: "vivo portomaso roulette",
      name: "Vivo Portomaso Roulette",
      providerName: "Vivo",
    },
    VARouletteVivo: {
      redirectUrl: "/casino/vivo-va-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/vivo/VARoulette.webp",
      alt: "",
      name: "VA Roulette",
      providerName: "Vivo",
    },

    // RouletteSn: {
    //   redirectUrl: "/casino/sn-roulette",
    //   code: "",
    //   casino: "wco",
    //   provider: "SN",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/roulette/supernowa/Roulette.webp",
    //   alt: "",
    //   name: "Roulette",
    //   providerName: "Supernowa",
    // },
  },

  baccarattab: {
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/Baccarat.webp",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    BaccaratEEzg: {
      redirectUrl: "/casino/ezg-baccarat-e",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/BaccaratE.webp",
      alt: "Baccarat E",
      name: "Baccarat E",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat1Ezg: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/CasinoMarinaBaccarat1.webp",
      alt: "Casino Marina Baccarat 1",
      name: "Casino Marina Baccarat 1",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat2Ezg: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/CasinoMarinaBaccarat2.webp",
      alt: "Casino Marina Baccarat 2",
      name: "Casino Marina Baccarat 2",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat3Ezg: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/CasinoMarinaBaccarat3.webp",
      alt: "Casino Marina Baccarat 3",
      name: "Casino Marina Baccarat 3",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat4Ezg: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-4",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/CasinoMarinaBaccarat4.webp",
      alt: "Casino Marina Baccarat 4",
      name: "Casino Marina Baccarat 4",
      providerName: "Ezugi",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/ezg-fortune-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/FortuneBaccarat.webp",
      alt: "Fortune Baccarat",
      name: "Fortune Baccarat",
      providerName: "Ezugi",
    },
    KnockoutBaccaratEzg: {
      redirectUrl: "/casino/ezg-knockout-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/KnockoutBaccarat.webp",
      alt: "Knockout Baccarat",
      name: "Knockout Baccarat",
      providerName: "Ezugi",
    },
    NoCommissionBaccaratAEzg: {
      redirectUrl: "/casino/ezg-no-commission-baccarat-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionBaccaratA.webp",
      alt: "No Commission Baccarat A",
      name: "No Commission Baccarat A",
      providerName: "Ezugi",
    },
    NoCommissionBaccaratBEzg: {
      redirectUrl: "/casino/ezg-no-commission-baccarat-b",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionBaccaratB.webp",
      alt: "No Commission Baccarat B",
      name: "No Commission Baccarat B",
      providerName: "Ezugi",
    },
    NoCommissionBaccaratCEzg: {
      redirectUrl: "/casino/ezg-no-commission-baccarat-c",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionBaccaratC.webp",
      alt: "No Commission Baccarat C",
      name: "No Commission Baccarat C",
      providerName: "Ezugi",
    },
    NoCommissionBaccaratDEzg: {
      redirectUrl: "/casino/ezg-no-commission-baccarat-d",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionBaccaratD.webp",
      alt: "No Commission Baccarat D",
      name: "No Commission Baccarat D",
      providerName: "Ezugi",
    },
    NoCommissionBaccaratEEzg: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionBaccaratE.webp",
      alt: "No Commission Baccarat E",
      name: "No Commission Baccarat E",
      providerName: "Ezugi",
    },
    NoCommissionOverUnderBaccaratEzg: {
      redirectUrl: "/casino/ezg-no-commission-over-under-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/NoCommissionOverUnderBaccarat.webp",
      alt: "No Commission Over Under Baccarat",
      name: "No Commission Over Under Baccarat",
      providerName: "Ezugi",
    },
    OverUnderBaccaratEzg: {
      redirectUrl: "/casino/ezg-over-under-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/OverUnderBaccarat.webp",
      alt: "Over Under Baccarat",
      name: "Over Under Baccarat",
      providerName: "Ezugi",
    },
    SpeedCricketBaccarat: {
      redirectUrl: "/casino/ezg-speed-cricket-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
      alt: "Speed Cricket Baccarat",
      name: "Speed Cricket Baccarat",
      providerName: "Ezugi",
    },
    SpeedFortuneBaccaratEzg: {
      redirectUrl: "/casino/ezg-speed-fortune-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/SpeedFortuneBaccarat.webp",
      alt: "Speed Fortune Baccarat",
      name: "Speed Fortune Baccarat",
      providerName: "Ezugi",
    },
    Super6BaccaratEzg: {
      redirectUrl: "/casino/ezg-super-6-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/Super6Baccarat.webp",
      alt: "Super 6 Baccarat",
      name: "Super 6 Baccarat",
      providerName: "Ezugi",
    },
    VIPFortuneBaccaratEzg: {
      redirectUrl: "/casino/ezg-vip-fortune-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/VIPFortuneBaccarat.webp",
      alt: "VIP Fortune Baccarat",
      name: "VIP Fortune Baccarat",
      providerName: "Ezugi",
    },
    VIPNoCommissionSpeedCricketBaccaratEzg: {
      redirectUrl: "/casino/ezg-vip-no-commission-speed-cricket-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl:
        "./images/baccarat/ezugi/VIPNoCommissionSpeedCricketBaccarat.webp",
      alt: "VIP No Commission Speed Cricket Baccarat",
      name: "VIP No Commission Speed Cricket Baccarat",
      providerName: "Ezugi",
    },

    BaccaratBEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-b",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/BaccaratB.webp",
      alt: "Baccarat B",
      name: "Baccarat B",
      providerName: "Evolution",
    },
    BaccaratCEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-c",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/BaccaratC.webp",
      alt: "Baccarat C",
      name: "Baccarat C",
      providerName: "Evolution",
    },
    BaccaratSqueezeEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-squeeze",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/BaccaratSqueeze.webp",
      alt: "Baccarat Squeeze",
      name: "Baccarat Squeeze",
      providerName: "Evolution",
    },
    FirstPersonBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-first-person-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/FirstPersonBaccarat.webp",
      alt: "First Person Baccarat",
      name: "First Person Baccarat",
      providerName: "Evolution",
    },
    FirstPersonGoldenWealthBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-first-person-golden-wealth-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl:
        "./images/baccarat/evolution/FirstPersonGoldenWealthBaccarat.webp",
      alt: "First Person Golden Wealth Baccarat",
      name: "First Person Golden Wealth Baccarat",
      providerName: "Evolution",
    },
    FirstPersonLightningBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-first-person-lightning-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/FirstPersonLightningBaccarat.webp",
      alt: "First Person Lightning Baccarat",
      name: "First Person Lightning Baccarat",
      providerName: "Evolution",
    },
    GoldenWealthBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-golden-wealth-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/GoldenWealthBaccarat.webp",
      alt: "Golden Wealth Baccarat",
      name: "Golden Wealth Baccarat",
      providerName: "Evolution",
    },
    LightningBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-lightning-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/LightningBaccarat.webp",
      alt: "Lightning Baccarat",
      name: "Lightning Baccarat",
      providerName: "Evolution",
    },
    NoCommissionBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-no-commission-baccarat-evo",
      code: "1000018",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/NoCommissionBaccarat.webp",
      alt: "No Commission Baccarat",
      name: "No Commission Baccarat",
      providerName: "Evolution",
    },
    SpeedBaccaratAEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratA.webp",
      alt: "Speed Baccarat A",
      name: "Speed Baccarat A",
      providerName: "Evolution",
    },
    SpeedBaccaratBEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-b",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratB.webp",
      alt: "Speed Baccarat B",
      name: "Speed Baccarat B",
      providerName: "Evolution",
    },
    SpeedBaccaratCEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-c",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratC.webp",
      alt: "Speed Baccarat C",
      name: "Speed Baccarat C",
      providerName: "Evolution",
    },
    SpeedBaccaratDEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-d",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratD.webp",
      alt: "Speed Baccarat D",
      name: "Speed Baccarat D",
      providerName: "Evolution",
    },
    SpeedBaccaratEEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-e",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratE.webp",
      alt: "Speed Baccarat E",
      name: "Speed Baccarat E",
      providerName: "Evolution",
    },
    SpeedBaccaratFEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-f",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratF.webp",
      alt: "Speed Baccarat F",
      name: "Speed Baccarat F",
      providerName: "Evolution",
    },
    SpeedBaccaratGEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-g",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratG.webp",
      alt: "Speed Baccarat G",
      name: "Speed Baccarat G",
      providerName: "Evolution",
    },
    SpeedBaccaratHEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-h",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratH.webp",
      alt: "Speed Baccarat H",
      name: "Speed Baccarat H",
      providerName: "Evolution",
    },
    SpeedBaccaratIEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-i",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratI.webp",
      alt: "Speed Baccarat I",
      name: "Speed Baccarat I",
      providerName: "Evolution",
    },
    SpeedBaccaratJEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-j",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratJ.webp",
      alt: "Speed Baccarat J",
      name: "Speed Baccarat J",
      providerName: "Evolution",
    },
    SpeedBaccaratREvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-r",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratR.webp",
      alt: "Speed Baccarat R",
      name: "Speed Baccarat R",
      providerName: "Evolution",
    },
    SpeedBaccaratVEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-v",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratV.webp",
      alt: "Speed Baccarat V",
      name: "Speed Baccarat V",
      providerName: "Evolution",
    },
    SpeedBaccaratWEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-w",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratW.webp",
      alt: "Speed Baccarat W",
      name: "Speed Baccarat W",
      providerName: "Evolution",
    },
    SpeedBaccaratXEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-x",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/evolution/SpeedBaccaratX.webp",
      alt: "Speed Baccarat X",
      name: "Speed Baccarat X",
      providerName: "Evolution",
    },

    BaccaratAura: {
      redirectUrl: "/casino/aura-baccarat",
      code: "",
      casino: "aura",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/aura/Baccarat.webp",
      alt: "Baccarat",
      name: "Baccarat",
      providerName: "Aura",
    },
    Card29BaccaratAura: {
      redirectUrl: "/casino/aura-29-card-baccarat",
      code: "",
      casino: "aura",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/aura/29CardBaccarat.webp",
      alt: "29 Card Baccarat",
      name: "29 Card Baccarat",
      providerName: "Aura",
    },

    GalaxyBaccarat1Vivo: {
      redirectUrl: "/casino/vivo-galaxy-baccarat-1",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivo/GalaxyBaccarat1.webp",
      alt: "Galaxy Baccarat 1",
      name: "Galaxy Baccarat 1",
      providerName: "Vivo",
    },
    GalaxyBaccarat2Vivo: {
      redirectUrl: "/casino/vivo-galaxy-baccarat-2",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivo/GalaxyBaccarat2.webp",
      alt: "Galaxy Baccarat 2",
      name: "Galaxy Baccarat 2",
      providerName: "Vivo",
    },
    GalaxyBaccarat3Vivo: {
      redirectUrl: "/casino/vivo-galaxy-baccarat-3",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivo/GalaxyBaccarat3.webp",
      alt: "Galaxy Baccarat 3",
      name: "Galaxy Baccarat 3",
      providerName: "Vivo",
    },
    MacauBaccaratVivo: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivo/MacauBaccarat.webp",
      alt: "Macau Baccarat",
      name: "Macau Baccarat",
      providerName: "Vivo",
    },
    VABacarat1Vivo: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivo/MacauBaccarat.webp",
      alt: "VA Bacarat 1",
      name: "VA Bacarat 1",
      providerName: "Vivo",
    },
    VABacarat2Vivo: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivo/MacauBaccarat.webp",
      alt: "VA Bacarat 2",
      name: "VA Bacarat 2",
      providerName: "Vivo",
    },
    VABacarat3Vivo: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivo/MacauBaccarat.webp",
      alt: "VA Bacarat 3",
      name: "VA Bacarat 3",
      providerName: "Vivo",
    },
    VABacarat4Vivo: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivo/MacauBaccarat.webp",
      alt: "VA Bacarat 4",
      name: "VA Bacarat 4",
      providerName: "Vivo",
    },
    VABacarat5Vivo: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/vivo/MacauBaccarat.webp",
      alt: "VA Bacarat 5",
      name: "VA Bacarat 5",
      providerName: "Vivo",
    },

    BaccaratSn: {
      redirectUrl: "/casino/sn-baccarat",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/supernowa/Baccarat.webp",
      alt: "Baccarat",
      name: "Baccarat",
      providerName: "Supernowa",
    },
    RNGBaccaratSn: {
      redirectUrl: "/casino/sn-rng-baccarat",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/supernowa/Baccarat.webp",
      alt: "RNG Baccarat",
      name: "RNG Baccarat",
      providerName: "Supernowa",
    },
    // RNGBaccarat2020Sn: {
    //   redirectUrl: "/casino/sn-rng-baccarat-2020",
    //   code: "",
    //   casino: "wco",
    //   provider: "SN",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/baccarat/supernowa/RNGBaccarat2020.webp",
    //   alt: "RNG Baccarat 2020",
    //   name: "RNG Baccarat 2020",
    //   providerName: "Supernowa",
    // },
  },

  dragontiger: {
    DragonTigerEzg: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger//ezugi/DragonTiger.webp",
      alt: "Dragon Tiger",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    DragonTigerDaSorteEzg: {
      redirectUrl: "/casino/ezg-dragon-tiger-da-sorte",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger//ezugi/DragonTigerDaSorte.webp",
      alt: "Dragon Tiger da Sorte",
      name: "Dragon Tiger da Sorte",
      providerName: "Ezugi",
    },

    DragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/evolution/DragonTiger.webp",
      alt: "Dragon Tiger",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
    FirstPersonDragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-first-person-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/evolution/First PersonDragonTiger.webp",
      alt: "First Person Dragon Tiger",
      name: "First Person Dragon Tiger",
      providerName: "Evolution",
    },
    LighteningDragontigerEvo: {
      redirectUrl: "/casino/ezgevo-lightening-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/evolution/LightningDragonTiger.webp",
      alt: "Lightening Dragon tiger",
      name: "Lightening Dragon tiger",
      providerName: "Evolution",
    },

    DragonTigerAura: {
      redirectUrl: "/casino/aura-dragon-tiger",
      code: "",
      casino: "aura",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/aura/DragonTiger.webp",
      alt: "Dragon Tiger",
      name: "Dragon Tiger",
      providerName: "Aura",
    },
    DragonTigerVirtualAura: {
      redirectUrl: "/casino/aura-dragon-tiger-virtual",
      code: "",
      casino: "aura",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/aura/DragonTigerVirtual.webp",
      alt: "Dragon Tiger (Virtual)",
      name: "Dragon Tiger (Virtual)",
      providerName: "Aura",
    },

    DragonTigerVivo: {
      redirectUrl: "/casino/vivo-dragon-tiger",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/vivo/DragonTiger.webp",
      alt: "vivo Dragon Tiger",
      name: "Vivo Dragon Tiger",
      providerName: "Vivo",
    },
    VADragonTigerVivo: {
      redirectUrl: "/casino/vivo-dragon-tiger",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/vivo/VADragonTiger.webp",
      alt: "VA Dragon Tiger",
      name: "VA Dragon Tiger",
      providerName: "Vivo",
    },

    DragonTigerSn: {
      redirectUrl: "/casino/sn-dragon-tiger",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/supernowa/DragonTiger.webp",
      alt: "Dragon Tiger",
      name: "Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTigerSn: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/supernowa/RNGDragonTiger.webp",
      alt: "RNG Dragon Tiger",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020Sn: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragon-tiger/supernowa/RNGDragonTiger2020.webp",
      alt: "RNG Dragon Tiger 2020",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
  },

  blackjacktab: {
    // BlackjackAEzg: {
    //   redirectUrl: "/casino/ezg-blackjack-a",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/ezugi/BlackjackA.webp",
    //   alt: "Blackjack A",
    //   name: "Blackjack A",
    //   providerName: "Ezugi",
    // },
    BlackjackDaSorteEzg: {
      redirectUrl: "/casino/ezg-blackjack-da-sorte",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/BlackjackDaSorte.webp",
      alt: "Blackjack Da Sorte",
      name: "Blackjack Da Sorte",
      providerName: "Ezugi",
    },
    GoldBlackjack1Ezg: {
      redirectUrl: "/casino/ezg-gold-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/GoldBlackjack1.webp",
      alt: "Gold Blackjack 1",
      name: "Gold Blackjack 1",
      providerName: "Ezugi",
    },
    GoldBlackjack3Ezg: {
      redirectUrl: "/casino/ezg-gold-blackjack-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/GoldBlackjack3.webp",
      alt: "Gold Blackjack 3",
      name: "Gold Blackjack 3",
      providerName: "Ezugi",
    },
    Rumbablackjack1Ezg: {
      redirectUrl: "/casino/ezg-rumba-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/RumbaBlackjack1.webp",
      alt: "Rumba Blackjack 1",
      name: "Rumba Blackjack 1",
      providerName: "Ezugi",
    },
    Rumbablackjack4Ezg: {
      redirectUrl: "/casino/ezg-rumba-blackjack-4",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/RumbaBlackjack4.webp",
      alt: "Rumba Blackjack 4",
      name: "Rumba Blackjack 4",
      providerName: "Ezugi",
    },
    SpanishUnlimitedBlackjackEzg: {
      redirectUrl: "/casino/ezg-spanish-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/SpanishUnlimitedBlackjack.webp",
      alt: "Spanish Unlimited Blackjack",
      name: "Spanish Unlimited Blackjack",
      providerName: "Ezugi",
    },
    UnlimitedBlackjackEzg: {
      redirectUrl: "/casino/ezg-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/UnlimitedBlackjack.webp",
      alt: "Unlimited Blackjack",
      name: "Unlimited Blackjack",
      providerName: "Ezugi",
    },
    VIPDiamondBlackjackEzg: {
      redirectUrl: "/casino/ezg-vip-diamond-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/VIPDiamondBlackjack.webp",
      alt: "VIP Diamond Blackjack",
      name: "VIP Diamond Blackjack",
      providerName: "Ezugi",
    },
    VipSurrenderBlackjackEzg: {
      redirectUrl: "/casino/ezg-vip-surrender-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/VipSurrenderBlackjack.webp",
      alt: "Vip Surrender Blackjack",
      name: "Vip Surrender Blackjack",
      providerName: "Ezugi",
    },

    BlackjackAEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackA.webp",
      alt: "Blackjack A",
      name: "Blackjack A",
      providerName: "Evolution",
    },
    BlackjackPartyEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-party",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackParty.webp",
      alt: "Blackjack Party",
      name: "Blackjack Party",
      providerName: "Evolution",
    },
    BlackjackSilverAEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackSilverA.webp",
      alt: "Blackjack Silver A",
      name: "Blackjack Silver A",
      providerName: "Evolution",
    },
    BlackjackVIP10Evo: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-10",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVIP10.webp",
      alt: "Blackjack VIP 10",
      name: "Blackjack VIP 10",
      providerName: "Evolution",
    },
    BlackjackVIPAlphaEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-alpha",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVIPAlpha.webp",
      alt: "Blackjack VIP Alpha",
      name: "Blackjack VIP Alpha",
      providerName: "Evolution",
    },
    BlackjackVIPBetaEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-beta",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVIPBeta.webp",
      alt: "Blackjack VIP Beta",
      name: "Blackjack VIP Beta",
      providerName: "Evolution",
    },
    BlackjackVIPEEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-e",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVIPE.webp",
      alt: "Blackjack VIP E",
      name: "Blackjack VIP E",
      providerName: "Evolution",
    },
    BlackjackVIPGammaEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-gamma",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVIPGamma.webp",
      alt: "Blackjack VIP Gamma",
      name: "Blackjack VIP Gamma",
      providerName: "Evolution",
    },
    BlackjackVIPLEvo: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-l",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/BlackjackVIPL.webp",
      alt: "Blackjack VIP L",
      name: "Blackjack VIP L",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack10Evo: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-10",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/ClassicSpeedBlackjack10.webp",
      alt: "Classic Speed Blackjack 10",
      name: "Classic Speed Blackjack 10",
      providerName: "Evolution",
    },
    FirstPersonBlackjackEvo: {
      redirectUrl: "/casino/ezgevo-first-person-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/FirstPersonBlackjack.webp",
      alt: "First Person Blackjack",
      name: "First Person Blackjack",
      providerName: "Evolution",
    },
    FirstPersonLightningBlackjackEvo: {
      redirectUrl: "/casino/ezgevo-first-person-lightning-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/FirstPersonLightningBlackjack.webp",
      alt: "First Person Lightning Blackjack",
      name: "First Person Lightning Blackjack",
      providerName: "Evolution",
    },
    InfiniteBlackjackEvo: {
      redirectUrl: "/casino/ezgevo-infinite-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/InfiniteBlackjack.webp",
      alt: "Infinite Blackjack",
      name: "Infinite Blackjack",
      providerName: "Evolution",
    },
    LightningBlackjackEvo: {
      redirectUrl: "/casino/ezgevo-lightning-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/LightningBlackjack.webp",
      alt: "Lightning Blackjack",
      name: "Lightning Blackjack",
      providerName: "Evolution",
    },
    PowerBlackjackEvo: {
      redirectUrl: "/casino/ezgevo-power-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/PowerBlackjack.webp",
      alt: "Power Blackjack",
      name: "Power Blackjack",
      providerName: "Evolution",
    },
    SpeedBlackjackDEvo: {
      redirectUrl: "/casino/ezgevo-speed-blackjack-d",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/SpeedBlackjackD.webp",
      alt: "Speed Blackjack D",
      name: "Speed Blackjack D",
      providerName: "Evolution",
    },
    SpeedVIPBlackjackAEvo: {
      redirectUrl: "/casino/ezgevo-speed-vip-blackjack-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/SpeedVIPBlackjackA.webp",
      alt: "Speed VIP Blackjack A",
      name: "Speed VIP Blackjack A",
      providerName: "Evolution",
    },

    LasVegasBlackjackVivo: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/vivo/LasVegasBlackjack.webp",
      alt: "Las Vegas Blackjack",
      name: "Las Vegas Blackjack",
      providerName: "Vivo",
    },
    LimitlessBlackjackVivo: {
      redirectUrl: "/casino/vivo-limitless-blackjack",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/vivo/LimitlessBlackjack.webp",
      alt: "Limitless Blackjack",
      name: "Limitless Blackjack",
      providerName: "Vivo",
    },
  },

  tvShows: {
    Blackjack: {
      redirectUrl: "/casino/ezg-blackjack-1",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack.jpg",
      alt: "",
      name: "Blackjack",
      providerName: "Ezugi",
    },
    LightningDice: {
      redirectUrl: "/casino/ezgevo-lightning-dice",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/lightningdice.jpg",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    CrazyTime: {
      redirectUrl: "/casino/ezugi/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/CrazyTime.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    Megaball: {
      redirectUrl: "/casino/ezugi/Megaball",
      code: "1000141",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Megaball.jpg",
      alt: "",
      name: "Megaball",
      providerName: "Evolution",
    },
  },

  pokertab: {
    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
      code: "1000073",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/2handcasinoholem.jpg",
      alt: "",
      name: " 2 Hand Casino Holdem",
      providerName: "Evolution",
    },
    BetonTeenPatti: {
      redirectUrl: "/casino/ezugi/BetonTeenPatti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Betonteenpatti.jpg",
      alt: "",
      name: "Bet on Teen Patti",
      providerName: "Ezugi",
    },
    SideBetCity: {
      redirectUrl: "/casino/ezugi/SideBetCity",
      code: "1000115",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Sidebetcity.jpg",
      alt: "",
      name: "Side Bet City",
      providerName: "Evolution",
    },
    TeenPatti3card: {
      redirectUrl: "/casino/ezugi/TeenPatti3card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Teenpatti3card.jpg",
      alt: "",
      name: " Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    TexasHoldembonus: {
      redirectUrl: "/casino/ezugi/TexasHoldembonus",
      code: "1000111",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/TexasHoldembonus.jpg",
      alt: "",
      name: "Texas Holdem Bonus",
      providerName: "Evolution",
    },
    Ultimatetexasholdem: {
      redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
      code: "1000151",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/ultimatetaxasholden.jpg",
      alt: "",
      name: "Ultimate Texas Holdem",
      providerName: "Evolution",
    },
  },

  spribe: {
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.gif",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Mines.jpg",
      alt: "",
      name: "Mines",
      providerName: "Spribe",
    },
    goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Goal.jpg",
      alt: "",
      name: "Goal",
      providerName: "Spribe",
    },
    dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Dice.jpg",
      alt: "",
      name: "Dice",
      providerName: "Spribe",
    },
    plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Plinko.jpg",
      alt: "",
      name: "Plinko",
      providerName: "Spribe",
    },
    miniroulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/MiniROulette.jpg",
      alt: "",
      name: "Mini Roulette",
      providerName: "Spribe",
    },
    hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Hilo.jpg",
      alt: "",
      name: "Hilo",
      providerName: "Spribe",
    },
  },

  supernowa: {
    RNGCasinoQueen: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      code: "RCQ",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
      alt: "",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGCasinoQueen2020: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      code: "RCQ20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
      alt: "",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },
    RNGCricket2020: {
      redirectUrl: "/casino/sn-rng-cricket-2020",
      code: "RCKT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCricket2020.jpg",
      alt: "",
      name: "RNG Cricket 2020",
      providerName: "Supernowa",
    },
    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "VDT",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
      alt: "",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "VDT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
      alt: "",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
    RNGJoker: {
      redirectUrl: "/casino/sn-rng-joker",
      code: "VJKR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGJoker.jpg",
      alt: "",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKingRace: {
      redirectUrl: "/casino/sn-rng-king-race",
      code: "VCR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGKingRace.jpg",
      alt: "",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGLucky7.jpg",
      alt: "",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGTeenPatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      code: "VTP",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
      alt: "",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenPatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      code: "VTP20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
      alt: "",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
    RNGWorliMatka: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
      alt: "",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
  },

  vivo: {
    VABacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/Vivo/VABacarat1.jpg",
      name: "VA Bacarat 1",
      providerName: "Vivo",
    },
    VABacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/Vivo/VABacarat2.jpg",
      name: "VA Bacarat 2",
      providerName: "Vivo",
    },
    VABacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/Vivo/VABacarat3.jpg",
      name: "VA Bacarat 3",
      providerName: "Vivo",
    },
    VABacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/Vivo/VABacarat4.jpg",
      name: "VA Bacarat 4",
      providerName: "Vivo",
    },
    VABacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/Vivo/VABacarat5.jpg",
      name: "VA Bacarat 5",
      providerName: "Vivo",
    },
  },

  lobby: {
    ezugilobby: {
      redirectUrl: "/casino/ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/Ezugi.webp",
      alt: "",
      name: "Ezugi",
      providerName: "Ezugi",
    },
    evolutionlobby: {
      redirectUrl: "/casino/evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/Evolution.webp",
      alt: "",
      name: "Evolution",
      providerName: "Evolution",
    },
    supernowalobby: {
      redirectUrl: "/casino/supernowa",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/Supernowa.webp",
      alt: "",
      name: "Supernowa",
      providerName: "Supernowa",
    },
    vivolobby: {
      redirectUrl: "/casino/vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/Vivo.webp",
      alt: "",
      name: "Vivo",
      providerName: "Vivo",
    },
    qtechlobby: {
      redirectUrl: "/casino/qtech",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/Qtech.webp",
      alt: "",
      name: "Qtech",
      providerName: "Qtech",
    },
    spribelobby: {
      redirectUrl: "/spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/Spribe.webp",
      alt: "",
      name: "Spribe",
      providerName: "Spribe",
    },
    worldcasinolobby: {
      redirectUrl: "/casino/worldcasino",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/WorldCasino.webp",
      alt: "",
      name: "World Casino",
      providerName: "World Casino",
    },
    xpglobby: {
      redirectUrl: "/casino/xpg",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/XPG.webp",
      alt: "",
      name: "XPG",
      providerName: "XPG",
    },
    kingmakerlobby: {
      redirectUrl: "/kingmaker",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/KingMaker.webp",
      alt: "",
      name: "Kingmaker",
      providerName: "Kingmaker",
    },
    aesexylobby: {
      redirectUrl: "/casino/aesexy",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lobby/AeSexy.webp",
      alt: "",
      name: "AeSexy",
      providerName: "WorldCasino",
    },
  },
};
